import Rails from '@rails/ujs'

Rails.start()

import { FetchRequest } from '@rails/request.js'
import '@hotwired/turbo-rails'
import 'flowbite/dist/flowbite.turbo.js'
import Alpine from 'alpinejs'
import Tagify from '@yaireo/tagify'
import * as Componeer from 'componeer'

require('inputmask/dist/inputmask')
require('./src/nestedDynamicForm')

window.Tagify = Tagify
window.Alpine = Alpine
window.Componeer = Componeer
window.Rails = Rails
window.FetchRequest = FetchRequest

Alpine.start()
