export default function TagInput(
  currentTags,
  availableTags,
  sort = true,
  options = {}
) {
  return {
    initialize: function (
      inputElement,
      enforce = true,
      plugins = [],
      $dispatch = null,
      draggable = false
    ) {
      tagify = new Tagify(inputElement, {
        whitelist: sort ? this.sortedTags(availableTags) : availableTags,
        enforceWhitelist: enforce,
        dropdown: {
          maxItems: 20,
          classname: options.dropdownClassname || 'tags-look',
          enabled: 0,
          closeOnSelect: false,
        },
        templates: {
          dropdownHeader: function () {
            return `<header data-selector="tagify-suggestions-header" class="${
              this.settings.classNames.dropdownItem
            } header__item">
              ${options?.templating?.dropdownHeader || ''}
            </header>`
          },
        },
        ...options,
      })

      tagify.addTags(sort ? this.sortedTags(currentTags) : currentTags)
      tagify.on('dropdown:select', (e) => {
        if (e.detail.elm.dataset.selector === 'tagify-suggestions-header') {
          setTimeout(() => tagify.dropdown.hide(), 100)
        }
      })

      if (draggable) {
        function onDragEnd(_element) {
          const t = new Tagify(inputElement)
          t.updateValueByDOMTags()
        }

        new DragSort(tagify.DOM.scope, {
          selector: '.' + tagify.settings.classNames.tag,
          callbacks: { dragEnd: onDragEnd },
        })
      }

      plugins.forEach((plugin) => plugin(tagify, $dispatch))
    },
    sortedTags: function (tags) {
      if (typeof tags[0] === 'object') {
        return tags.sort((a, b) => (a.value >= b.value ? 1 : -1))
      } else {
        return tags.sort()
      }
    },
    selectedTags: currentTags,
  }
}
