;(function () {
  const nestedDynamicForm = function (attributes) {
    return {
      fields: [],
      addNewField() {
        this.fields.push(attrsToAdd(attributes))
      },
      removeField(index) {
        this.fields[index].destroy = true
      },
    }
  }

  function attrsToAdd(attributes) {
    return attributes.reduce(
      (hash, attribute) => ({ ...hash, [attribute]: '' }),
      {}
    )
  }

  if (typeof window !== 'undefined')
    window.nestedDynamicForm = nestedDynamicForm

  return nestedDynamicForm
})()
